function submitEmailList(e) {
  e.preventDefault();

  var inputElement = document.getElementById('email-list-input');
  var buttonElement = document.getElementById('email-list-submit');
  var originalText = buttonElement.innerHTML;

  var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailPattern.test(inputElement.value)) {
    buttonElement.style.backgroundColor = '#ff0000';
    buttonElement.innerHTML = 'Invalid Email';
    setTimeout(function () {
      buttonElement.style.backgroundColor = null;
      buttonElement.innerHTML = originalText;
    }, 2000);
    return false;
  }

  fetch('https://api.hohmey.com/email-list', {
    method: 'POST',
    body: JSON.stringify({
      email: inputElement.value,
    }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then(function () {
    buttonElement.innerHTML = 'Submitted';
    inputElement.value = '';
  });

  return false;
}

window.submitEmailList = submitEmailList;
